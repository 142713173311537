<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 搜索 -->
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="活动">
        <SelectActivity width="200px" v-model="searchData.activityId"></SelectActivity>
      </el-form-item>
      <el-form-item label="用户姓名">
        <SelectCustomer width="200px" v-model="searchData.uid"></SelectCustomer>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- list -->
    <el-table :data="list">
      <!-- 拓展 -->
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form label-position="left">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="活动详情">
                  <div class="table-content-expand">
                    <div class="item">
                      <span>活动名称：</span>
                      <span>{{ row.activityInfo?.name }}</span>
                    </div>

                    <div class="item">
                      <span>活动开始时间：</span>
                      <span>{{ row.activityInfo?.startTime }}</span>
                    </div>
                    <div class="item">
                      <span>活动结束时间：</span>
                      <span>{{ row.activityInfo?.endTime }}</span>
                    </div>
                    <div class="item">
                      <span>活动状态：</span>
                      <span>
                        <el-tag v-if="row.activityInfo.status == 0" type="warning" size="mini">待审核</el-tag>
                        <el-tag v-if="row.activityInfo.status == 1" type="primary" size="mini">审核通过</el-tag>
                        <el-tag v-if="row.activityInfo.status == 2" type="danger" size="mini">审核拒绝</el-tag>
                      </span>
                    </div>
                    <div class="item">
                      <span>活动地址：</span>
                      <span>{{ row.activityInfo.address }}</span>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户详情">
                  <div class="table-content-expand">
                    <div class="item">
                      <span>昵称：</span>
                      <span>{{ row.userInfo?.nickname }}</span>
                    </div>

                    <div class="item">
                      <span>姓名：</span>
                      <span>{{ row.userInfo?.realname }}</span>
                    </div>
                    <div class="item">
                      <span>手机号：</span>
                      <span>{{ row.userInfo?.mobile }}</span>
                    </div>
                    <div class="item">
                      <span>头像：</span>
                      <span>
                        <el-image
                          style="width: 60px; height: 60px"
                          :src="row.userInfo?.face"
                          :preview-src-list="[row.userInfo?.face]"
                          fit="contain"
                        ></el-image>
                      </span>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column prop="activityInfo" label="活动名称" min-width="120" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.activityInfo?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="activityInfo" label="用户姓名" min-width="120" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.userInfo?.realname }}
        </template>
      </el-table-column>
      <el-table-column prop="activityInfo" label="用户头像" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px; border-radius: 5px"
            :src="row.userInfo?.face"
            :preview-src-list="[row.userInfo?.face]"
            fit="contain"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="活动状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="mini">待审核</el-tag>
          <el-tag v-if="row.status == 1" type="primary" size="mini">审核通过</el-tag>
          <el-tag v-if="row.status == 2" type="danger" size="mini">审核拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="申请内容" min-width="160" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="createTime" label="申请时间" min-width="160" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link v-if="row.status == 0" style="margin-right: 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情</el-link>
          <el-link type="primary" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />

    <!-- 详情弹窗 -->
    <el-drawer size="45%" :visible.sync="show_detail" direction="rtl">
      <Detail :detail="detail"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, delAPI, reviewAPI, getDetailAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectActivity from '@/views/activity/activity-list/select-activity-list.vue'
import SelectCustomer from '@/views/customer/customer-list/select-customer-list.vue'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectActivity, SelectCustomer, Detail },
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        activityId: '',
        uid: ''
      },
      total: 0,
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    // 列表
    async getList() {
      const res = await getListAPI(this.searchData)
      this.total = res.totalRow
      this.list = res.records
    },

    // 删除
    handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 审核按钮
    handleReview({ id }) {
      this.reviewData.id = id
      this.show_review = true
    },
    // 提交审核
    submitReview() {
      reviewAPI(this.reviewData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    // 详情按钮
    async handleDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .table-content {
    .item {
      display: flex;
      align-items: center;
      span {
        &:first-child {
          width: 70px;
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
  .table-content-expand {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .item {
      display: flex;
      align-items: center;
      & > span {
        &:first-child {
          width: 120px;
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
  .el-drawer {
    .el-drawer__header {
      padding: 0;
    }
  }
}
</style>
