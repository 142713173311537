import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/activity/evaluation/page`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/manage/activity/evaluation/delete?id=${id}`,
    method: 'post',
    data: {}
  })
}
// export function addAPI(data) {
//   return http({
//     url: `/manage/activity/requirement/create`,
//     method: 'post',
//     data
//   })
// }

// export function editAPI(data) {
//   return http({
//     url: `/manage/activity/requirement/update`,
//     method: 'post',
//     data
//   })
// }

export function getDetailAPI(id) {
  return http({
    url: `/manage/activity/evaluation/detail`,
    method: 'get',
    params: { id }
  })
}
